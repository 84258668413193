import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { FaCopy } from "react-icons/fa";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import "./MainPage.css";
import "./scroller.scss";
// import Footer from '../commons/Footer/Footer'
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { useNavigate } from "react-router-dom";
import Footer from "../commons/Footer/Footer";
import Instructions from "./Instructions";
const { Configuration, OpenAIApi } = require("openai");
const MainPage = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [code, setCode] = React.useState(`Ansible Playbook Editor`);
  const [error, setError] = useState("");
  // changing the api key
  const configuration = new Configuration({
    apiKey: "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",
  });
  const openai = new OpenAIApi(configuration);

  // copy to clipboard function
  const copyClipboard = () => {
    toast.info("Code copied!", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    navigator.clipboard.writeText(code);
  };

  // modal functions
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // form functions
  const handleSubmit = async (event) => {
    event.preventDefault();
    // loading screen
    setCode("Generating Ansible Playbook..  Wait!");
    const query = event.target.query.value;
    const response = await openai.createCompletion({
      model: "text-davinci-002",
      prompt: `Generate ansible playbook to: ${query}`,
      temperature: 0.75,
      max_tokens: 521,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });
    if (response.status === 200) {
      console.log(response.data.choices[0].text.substring(3));
      setCode(response.data.choices[0].text.substring(3));
      setError("");
    }
  };
  console.log(user);
  return (
    <div className="main-page">
      <Container fluid>
        <div className="main-page-style">
          <Row>
            <Col xs={12} md={6} lg={4}>
              <div className="half-input">
                <h3 className="my-3 fw-bold">
                  AI for Ansible Content Development{" "}
                </h3>
                <p>Easily Generate, Customize, & Use! </p>
                <div className="inbux-box">
                  <form onSubmit={handleSubmit} className="form-style">
                    <div className="mb-2">
                      <input
                        className="input-style"
                        type="text"
                        placeholder="Enter the task/operation to automate"
                        id="query"
                      />
                    </div>
                    <div>
                      {user ? (
                        <>
                          {user?.emailVerified ? (
                            <button type="submit" className="generate-code-btn">
                              Generate Playbook
                            </button>
                          ) : (
                            <>
                              <p
                                onClick={() =>
                                  setError("Please verify your account!")
                                }
                                className="generate-code-para"
                              >
                                Generate Playbook
                              </p>
                              <p className="error-style mb-0">
                                {error && error}
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <button
                          onClick={() => navigate("/login")}
                          className="generate-code-btn"
                        >
                          Generate Code
                        </button>
                      )}
                    </div>
                  </form>
                </div>
                <div className="modal-div-para">
                  <p onClick={handleShow} className="modal-para">
                    Review best practices
                  </p>
                </div>
                <div className="mt-2 mb-2">
                  {/* <div class="box">
                    <ul>
                      <li class="item-1">
                        Open network port on Palo Alto firewall
                      </li>
                      <li class="item-2">
                        Take backup of cisco network device
                      </li>
                      <li class="item-3">
                        Perform health check on linux system
                      </li>
                      <li class="item-4">Run CIS benchmark on RHEL8</li>
                      <li class="item-5">
                        Installation of .NET app on Windows
                      </li>
                      <li class="item-6">Perform Postgresql database Backup</li>
                      <li class="item-6">Take snapshot of VM on VMware</li>
                    </ul>
                  </div> */}
                  <h3 className="my-3"></h3>
                  <p className=" text-center">
                    {" "}
                    Save time and get unstuck. Tell ansible.ai what you’re
                    thinking to automate in your IT infrastructure and it will
                    generate syntactically correct playbook to help you get
                    there.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} lg={8} className="half-input-div">
              <div className="Code">
                <Editor
                  value={code}
                  onValueChange={(code) => setCode(code)}
                  highlight={(code) => highlight(code, languages.js)}
                  padding={15}
                  style={{
                    // fontFamily: '"Fira code", "Fira Mono", monospace',
                    // borderRadius: '6px',
                    border: "none",
                    // background: '#070808',
                    // color: 'white',
                    minHeight: "400px",
                  }}
                />
              </div>
              <div className="copy-btn-div">
                <button className="btn-dark btn" onClick={copyClipboard}>
                  <span style={{ color: "#999" }}>
                    <FaCopy />
                  </span>
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <ToastContainer />
        <Instructions show={show} handleClose={handleClose}></Instructions>
      </Container>
      <Footer></Footer>
    </div>
  );
};

export default MainPage;
