import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import { BsEnvelopeFill } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
const Footer = () => {
  return (
    <footer className="footer-style">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center">
          <Row>
            <Col
              className="d-flex justify-content-center align-items-start"
              xs={12}
              md={6}
              lg={4}
            >
              <p className="mb-0 text-start footer-para">
                Stay tuned for the{" "}
                <a
                  href="https://www.redhat.com/en/engage/project-wisdom"
                  className="footer-link"
                >
                  Project Wisdom
                </a>
                . Red Hat and IBM are training an AI model to bring fine-tuned
                AI pair for Ansible Content Development.
              </p>
            </Col>
            <Col
              className="d-flex justify-content-center align-items-start"
              xs={12}
              md={6}
              lg={4}
            >
              <p className="mb-0 text-start footer-para">
                The <b>ansible.ai</b> is neither affiliated with{" "}
                <a
                  href="https://www.redhat.com/en/engage/project-wisdom"
                  className="footer-link"
                >
                  Project Wisdom
                </a>{" "}
                nor with Red Hat. Ansible® is a registered trademark of Ansible,
                Inc and Red Hat, Inc in the United States and other countries.
              </p>
            </Col>
            <Col
              className="d-flex justify-content-center align-items-start"
              xs={12}
              md={6}
              lg={4}
            >
              <p className="mb-0 text-start footer-para">
                For feedback and enquiry contact: <br />{" "}
                <BsEnvelopeFill className="email-icon" /> eprasad96@gmail.com <br />{" "}
	        <BsLinkedin className="linkedin-icon" /> <a
                  href="https://www.linkedin.com/in/prasad-mukhedkar-0148a021b/"
                  className="footer-link"
                >
                  Click Here
                </a>{" "}
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
