import { signOut } from 'firebase/auth'
import React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Link, useNavigate } from 'react-router-dom'
import auth from '../../../firebase.init'
import './Header.css'
import {GiHamburgerMenu} from 'react-icons/gi'
const Header = () => {
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const handleSignOut = () => {
    signOut(auth)
    navigate('/')
  }
  return (
    <div>
      <section class='top-nav'>
        <div>
          <Link to='/'>
            <img src="https://i.ibb.co/jTbQqBt/Ansibleai.png" height="80px" width="190px" alt="" />
          </Link>
        </div>
        <input id='menu-toggle' type='checkbox' />
        <label class='menu-button-container' for='menu-toggle'>
          <div class='menu-button'><GiHamburgerMenu className='btn-size' /></div>
        </label>
        <ul class='menu'>
          {/* {
            user && <li className='list-style'>
              <Link to='/main'>Home</Link>
            </li>
          }
          <li className='list-style'>
            <Link to='/'>Prices</Link>
          </li>
          <li className='list-style'>
            <Link to='/'>Blog</Link>
          </li>
          <li className='list-style'>
            <Link to='/'>Resource</Link>
          </li>
          <li className='list-style'>
            <Link to='/'>Wall of Love</Link>
          </li>
          <li className='list-style'>
            <Link to='/'>Contact</Link>
          </li> */}
          <li className='list-style'>
            {
              user ?
                <button
                  onClick={handleSignOut}
                  className='get-started-btn'
                >
                  Sign Out
                </button>
                :
                <button
                  onClick={() => navigate('/login')}
                  className='get-started-btn'
                >
                  Login
                </button>
            }

          </li>
        </ul>
      </section>
    </div>
  )
}

export default Header
