import React from "react";
import { Modal } from "react-bootstrap";
import './Instructions.css'
import {FaTimes} from 'react-icons/fa'
const Instructions = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered >
      <div className="modal-div">
        <p>Review best practices</p>
        <ul className="list-style-modal">
          <li>
            The Input should be a simple description of the automation task that
            want to automate. E.g, configure ntp on cisco catalyst switches,
            Stop all instances with 'test' tag on aws
          </li>
          <li>Avoid use of Write or Generate playbook in the prompt</li>
          <li>
            Avoid having extra spaces and new line characters, quotation marks
          </li>
          <li>
            If you do not get the desired results, try to retype your question.
          </li>
          <li>
            If you do not get proper playbook/role, click on 'Generate Playbook'
            button to get new playbook. Continue this until you get desired
            playbook
          </li>
        </ul>
            <button className="okay-button" onClick={handleClose}>Okay</button>
            <button className="close-button" onClick={handleClose}><FaTimes /></button>
      </div>
    </Modal>
  );
};

export default Instructions;
