import React, { useState } from "react";
import { Container, FloatingLabel, Form } from "react-bootstrap";
import {
  useCreateUserWithEmailAndPassword,
  useSendEmailVerification,
  useSignInWithGoogle,
  useUpdateProfile,
} from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import auth from "../../firebase.init";
import "./Register.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../commons/Loading/Loading";
import { Button } from "react-bootstrap";
const Register = () => {
  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);
  const [sendEmailVerification, sending] = useSendEmailVerification(auth);
  const [updateProfile, updating] = useUpdateProfile(auth);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [signInWithGoogle, guser] = useSignInWithGoogle(auth);
  if (loading || updating) {
    return <Loading></Loading>;
  }

  if (user || guser) {
    if (user?.reloadUserInfo?.emailVerified) {
      navigate("/");
    } else {
      navigate("/verify-email");
    }
  }
  if (sending) {
    toast("Verification email has been sent!");
  }

  const handleCreateUser = async (event) => {
    event.preventDefault();
    const fname = event.target.firstName.value;
    const lname = event.target.lastName.value;
    const email = event.target.email.value;
    const password = event.target.password.value;
    const ConfirmPassword = event.target.ConfirmPassword.value;
    if (password.length < 6) {
      setMessage("Password's length must be 6");
    } else {
      if (password === ConfirmPassword) {
        if (!user) {
          await createUserWithEmailAndPassword(email, password);
          await updateProfile({ displayName: `${fname}/${lname}` });
          await sendEmailVerification();
        }
      } else {
        setMessage("Passwords didn't match");
      }
    }
  };
  const handleSignInWithGoogle = () => {
    signInWithGoogle();
  };
  return (
    <div>
      <Container fluid className="register-page">
        <div className="register-style mx-auto p-4">
          <h3>Create a New Account</h3>
          <form onSubmit={handleCreateUser}>
            <FloatingLabel
              controlId="floatingInput"
              label="First Name"
              className="mb-3"
            >
              <Form.Control
                type="name"
                name="firstName"
                placeholder="Your First Name"
                required
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Last Name"
              className="mb-3"
            >
              <Form.Control
                type="name"
                name="lastName"
                placeholder="Your Last Name"
                required
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Email address"
              className="mb-3"
            >
              <Form.Control
                type="email"
                name="email"
                placeholder="name@example.com"
                required
              />
            </FloatingLabel>
            <FloatingLabel
              className="mb-3"
              controlId="floatingPassword"
              label="Password"
            >
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                required
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingPassword"
              label="Confirm Password"
            >
              <Form.Control
                type="password"
                name="ConfirmPassword"
                placeholder="Confirm Password"
                required
              />
            </FloatingLabel>
            {error && (
              <p className="text-start text-danger error-message-style">
                {error.message.split("/")[1].split(")")[0].split("-").join(" ")}
              </p>
            )}
            {message && (
              <p className="text-start text-danger error-message-style">
                {message}
              </p>
            )}
            <button className="w-100 mt-3 login-btn" type="submit">
              Register
            </button>
          </form>
          <p className="text-start m-2">
            Already have an account?
            <Link className="text-primary ms-1 fw-bold" to="/login">
              Login
            </Link>{" "}
          </p>

          <Button onClick={handleSignInWithGoogle} className="login-btn mt-3">
            Sign in with Google
          </Button>
        </div>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default Register;
