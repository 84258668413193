import React, { useRef, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import './Login.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSendPasswordResetEmail, useSignInWithEmailAndPassword, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../commons/Loading/Loading';
const Login = () => {
    const [
        signInWithEmailAndPassword,
        user,
        loading,
        error,
    ] = useSignInWithEmailAndPassword(auth);
    const [sendPasswordResetEmail] = useSendPasswordResetEmail(auth);
    const navigate = useNavigate();
    const emailRef = useRef('');
    const passwordRef = useRef('');
    const [message, setMessage] = useState('');
    const [signInWithGoogle, guser] = useSignInWithGoogle(auth);

    let location = useLocation();
    let from = location.state?.from?.pathname || "/";
    if (loading) {
        return <Loading></Loading>
    }
    if (user || guser) {
        navigate(from, { replace: true });;
    }

    const handleSignIn = async event => {
        event.preventDefault();
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        await signInWithEmailAndPassword(email, password);
    }

    const handlePasswordReset = async event => {
        event.preventDefault();
        const email = emailRef.current.value;
        if (email) {
            await sendPasswordResetEmail(email);
            toast("Password reset email sent!")
        }
        else {
            setMessage("Enter your email!");
        }

    }
    const handleSignInWithGoogle = () => {
        signInWithGoogle();
    }
    return (
        <div className='login-page-style'>
            <Container fluid>
                <Row>
                    <Col className="login-half" xs={12} md={7} lg={7}>
                        <h2 className="fw-bold">Ansible Playbook in seconds</h2>
                        <p>With Ansible.ai, engineers and non-engineers can easily write efficient, and syntactically correct ansible playbooks.
                            It's time to take back your time! </p>
                    </Col>
                    <Col xs={12} md={5} lg={5}>
                        <div className="login-style">
                            <h3 className='text-center mb-4'>Login</h3>
                            <Form onSubmit={handleSignIn} className='w-75 mx-auto form-style'>
                                <Form.Floating className="mb-3">
                                    <Form.Control
                                        ref={emailRef}
                                        id="floatingInputCustom"
                                        type="email"
                                        placeholder="name@example.com"
                                        required
                                    />
                                    <label htmlFor="floatingInputCustom">Email address</label>
                                </Form.Floating>
                                <Form.Floating>
                                    <Form.Control
                                        id="floatingPasswordCustom"
                                        type="password"
                                        ref={passwordRef}
                                        placeholder="Password"
                                        required
                                    />
                                    <label htmlFor="floatingPasswordCustom">Password</label>
                                </Form.Floating>
                                {error && <p className='text-start text-danger'>{error.message}</p>}
                                {message && <p className='text-start text-danger'>{message}</p>}
                                <div className='d-flex justify-content-between align-items-center'>
                                    <Form.Group className="" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Remember me" />
                                    </Form.Group>
                                    <Button onClick={handlePasswordReset} className='link-style' variant="link">Forgot Password</Button>
                                </div>
                                <Button className='login-btn' type="submit">
                                    Login
                                </Button>
                                <p className='text-start'>Don't have an account? <Link className='link-style' to='/register'>Register Now</Link></p>
                                <Button onClick={handleSignInWithGoogle} className='login-btn mt-3'>
                                    Sign in with Google
                                </Button>
                            </Form>

                        </div>

                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    );
};

export default Login;
