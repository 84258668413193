import React from "react";
import { Image } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import auth from "../../../firebase.init";

const VerifyEmail = () => {
  const [ user ] = useAuthState(auth);
  console.log(user)
  const navigate = useNavigate()
  
  return (
    <div>
      <Image
        className="mt-4"
        src="https://img.freepik.com/free-vector/verified-concept-illustration_114360-5167.jpg?size=338&ext=jpg&ga=GA1.2.1964215507.1670706074&semt=sph"
      />
      <h3 className="mt-3 fw-bold">
        Please Check your Email and Verify your account
      </h3>
    </div>
  );
};

export default VerifyEmail;
