import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from './components/commons/Header/Header'
import { Route, Routes } from 'react-router-dom'
// import Home from './components/Home/Home'
import Login from './components/Login/Login'
import Register from './components/Register/Register'
import NotFound from './components/commons/NotFound/NotFound'
import 'react-toastify/dist/ReactToastify.css'
import MainPage from './components/MainPage/MainPage'
import VerifyEmail from './components/commons/VerifyEmail/VerifyEmail'
// import RequiredAuth from './components/commons/RequiredAuth/RequiredAuth'
function App() {
  return (
    <div className='App'>
      <Header></Header>
      <Routes>
        {/* <Route path='/' element={<Home></Home>}></Route> */}
        <Route path='/' element={<MainPage></MainPage>}></Route>
        <Route path='/login' element={<Login></Login>}></Route>
        <Route path='/register' element={<Register></Register>}></Route>
        <Route path='/verify-email' element={<VerifyEmail></VerifyEmail>}></Route>
        <Route path='*' element={<NotFound></NotFound>}></Route>
      </Routes>
    </div>
  )
}

export default App
